const menuItems = {
    items: [
        //{
        //    id: 'navigation',
        //    languageKey: 'COMMON.Navigation',
        //    title: 'Navigation',
        //    type: 'group',
        //    applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
        //    screenID: '',
        //    icon: 'icon-navigation',
        //    children: [
        //        {
        //            id: 'home',
        //            languageKey: 'COMMON.Home',
        //            title: 'Home',
        //            type: 'collapse',
        //            applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
        //            screenID: '',
        //            icon: 'feather icon-home',
        //            children: [
        //                {
        //                    id: 'default',
        //                    languageKey: 'COMMON.Default',
        //                    title: 'Default',
        //                    type: 'item',
        //                    applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
        //                    screenID: '5bfb3236-07bb-11ea-a23c-3ca82aa779fb',
        //                    url: '/app/home/dashboard'
        //                }
        //            ]
        //        }
        //    ]
        //},
        {
            id: 'sysadmin',
            languageKey: 'COMMON.System Admin',
            title: 'System Admin',
            type: 'group',
            applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'sysadmin',
                    languageKey: 'COMMON.System Admin',
                    title: 'System Admin',
                    type: 'collapse',
                    applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        {
                            id: 'user_management',
                            languageKey: 'SYSADMIN.User Management',
                            title: 'User Management',
                            type: 'item',
                            applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                            screenID: '5c0b9a5b-07bb-11ea-a23c-3ca82aa779fb',
                            url: '/app/sysadmin/user_management/user_listing'
                        },
                        {
                            id: 'role_management',
                            languageKey: 'SYSADMIN.User Role Management',
                            title: 'User Role Management',
                            type: 'item',
                            applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                            screenID: '182e50a2-c681-4a23-8d76-241ffdf4dfa9',
                            url: '/app/sysadmin/user_management/user_role_listing'
                        },
                        //{
                        //    id: 'scorecard_management',
                        //    languageKey: 'SYSADMIN.Scorecard Management',
                        //    title: 'Scorecard Management',
                        //    type: 'item',
                        //    applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                        //    screenID: '1b3b5394-9782-421f-9426-c28e56b57f5d',
                        //    url: '/app/sysadmin/scorecard_management/scorecard_listing'
                        //},
                        {
                            id: 'fiscal_year_management',
                            languageKey: 'SYSADMIN.Fiscal Year Management',
                            title: 'Fiscal Year Management',
                            type: 'item',
                            applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                            screenID: 'acf347e5-eabb-4793-871b-f1331de512ab',
                            url: '/app/sysadmin/fiscal_year_management'
                        }
                    ]
                }
            ]
        }
    ]
};

export default menuItems;
