import React, { useEffect, useContext, useState } from "react";
import { ListGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RequireForAccess, { ReactAccessProvider } from 'react-access';

import useWindowSize from "../../../../hooks/useWindowSize";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import change_service from '../../../../common/change_service/change_service';
import LanguageChangeModal from '../../../../components/Modal/LanguageChangeModal/LanguageChangeModal';
import AnimatedModal from '../../../../components/Modal/AnimatedModal';
import useAuth from '../../../../hooks/useAuth';

const NavLeft = () => {
    const { language, languages, access_rights } = useAuth();
    const [applicationAccessData, setApplicationAccessData] = useState([]);
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const windowSize = useWindowSize();

    const configContext = useContext(ConfigContext);
    const { enable_language_change_flag, rtlLayout } = configContext.state;

    let dropdownRightAlign = false;
    if (rtlLayout) {
        dropdownRightAlign = true;
    }

    let navItemClass = ['nav-item'];
    if (windowSize.width <= 575) {
        navItemClass = [...navItemClass, 'd-none'];
    }

    const handleShowLanguageModal = (lang) => {
        setSelectedLanguage(lang);
        setShowLanguageModal(true);
    }

    const handleChangeServices = (service) => {
        var change_service_data = change_service();
        if (service == "System Admin") {
        }
        else if (service == "ERM") {
            var service_location = process.env.REACT_APP_ERM_URL + "?ChangeService=" + change_service_data;
            window.location = service_location;
        }
        else if (service == "Incident Reporting") {
            var service_location = process.env.REACT_APP_INCIDENT_REPORTING_URL + "?ChangeService=" + change_service_data;
            window.location = service_location;
        }
        else if (service == "KRI") {
            var service_location = process.env.REACT_APP_KRI_URL + "?ChangeService=" + change_service_data;
            window.location = service_location;
        }
    }

    useEffect(() => {
        const accessData = [...new Set(access_rights.map(p => p.applicationID))];
        setApplicationAccessData(accessData);
    }, [access_rights]);

    useEffect(() => {
        const accessData = [...new Set(access_rights.map(p => p.applicationID))];
        setApplicationAccessData(accessData);
    }, []);

    return (
        <>
            <React.Fragment>
                <ListGroup as='ul' bsPrefix=' ' className="navbar-nav">
                    <ListGroup.Item as='li' bsPrefix=' ' className="nav-item">
                        <Dropdown alignRight={dropdownRightAlign}>
                            <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                                {languages.filter(p => p.languageKey == "COMMON.Module")["0"][language]}
                                &nbsp;
                                <ReactAccessProvider permissions={enable_language_change_flag}>
                                    <RequireForAccess
                                        permissions={['true']}
                                    >
                                        <span onClick={() => handleShowLanguageModal("COMMON.Module")}>
                                            <i className="fa fa-language" aria-hidden="true"></i>
                                        </span>
                                    </RequireForAccess>
                                </ReactAccessProvider>
                            </Dropdown.Toggle>
                            <ul>
                                <Dropdown.Menu>
                                    <ReactAccessProvider permissions={applicationAccessData}>
                                        <RequireForAccess
                                            permissions={['54f63072-07b8-11ea-a23c-3ca82aa779fb']}
                                        >
                                            <li className="padding-10px">
                                                <Link to='#' className="dropdown-item display-inline" onClick={() => handleChangeServices("System Admin")}>
                                                    {languages.filter(p => p.languageKey == "COMMON.System Admin")["0"][language]}
                                                </Link> &nbsp;
                                                <ReactAccessProvider permissions={enable_language_change_flag}>
                                                    <RequireForAccess
                                                        permissions={['true']}
                                                    >
                                                        <span onClick={() => handleShowLanguageModal("COMMON.System Admin")}>
                                                            <i className="fa fa-language" aria-hidden="true"></i>
                                                        </span>
                                                    </RequireForAccess>
                                                </ReactAccessProvider>
                                            </li>
                                        </RequireForAccess>
                                    </ReactAccessProvider>
                                    <ReactAccessProvider permissions={applicationAccessData}>
                                        <RequireForAccess
                                            permissions={['22ba2e9f-cce3-4107-9645-491f7ae2a834', '553c9d59-9bd6-47e5-9d56-3b5a1caf2184']}
                                        >
                                            <li className="padding-10px">
                                                <Link to='#' className="dropdown-item display-inline" onClick={() => handleChangeServices("ERM")}>
                                                    {languages.filter(p => p.languageKey == "COMMON.ERM")["0"][language]}
                                                </Link> &nbsp;
                                                <ReactAccessProvider permissions={enable_language_change_flag}>
                                                    <RequireForAccess
                                                        permissions={['true']}
                                                    >
                                                        <span onClick={() => handleShowLanguageModal("COMMON.ERM")}>
                                                            <i className="fa fa-language" aria-hidden="true"></i>
                                                        </span>
                                                    </RequireForAccess>
                                                </ReactAccessProvider>
                                            </li>
                                        </RequireForAccess>
                                    </ReactAccessProvider>
                                    <ReactAccessProvider permissions={applicationAccessData}>
                                        <RequireForAccess
                                            permissions={['52e0f77f-6163-4384-933e-3d5ab5a0a32d', '52e0f77f-6163-4384-933e-3d5ab5a0a32d']}
                                        >
                                            <li className="padding-10px">
                                                <Link to='#' className="dropdown-item display-inline" onClick={() => handleChangeServices("Incident Reporting")}>
                                                    {languages.filter(p => p.languageKey == "COMMON.Incident Reporting")["0"][language]}
                                                </Link> &nbsp;
                                                <ReactAccessProvider permissions={enable_language_change_flag}>
                                                    <RequireForAccess
                                                        permissions={['true']}
                                                    >
                                                        <span onClick={() => handleShowLanguageModal("COMMON.Incident Reporting")}>
                                                            <i className="fa fa-language" aria-hidden="true"></i>
                                                        </span>
                                                    </RequireForAccess>
                                                </ReactAccessProvider>
                                            </li>
                                        </RequireForAccess>
                                    </ReactAccessProvider>
                                    <ReactAccessProvider permissions={applicationAccessData}>
                                        <RequireForAccess
                                            permissions={['369ba5b6-9b2a-45a1-bca8-83bbc1c08964', 'fb8b73a6-6612-41c6-81f5-5b1b12978856']}
                                        >
                                            <li className="padding-10px">
                                                <Link to='#' className="dropdown-item display-inline" onClick={() => handleChangeServices("KRI")}>
                                                    {languages.filter(p => p.languageKey == "COMMON.KRI")["0"][language]}
                                                </Link> &nbsp;
                                                <ReactAccessProvider permissions={enable_language_change_flag}>
                                                    <RequireForAccess
                                                        permissions={['true']}
                                                    >
                                                        <span onClick={() => handleShowLanguageModal("COMMON.KRI")}>
                                                            <i className="fa fa-language" aria-hidden="true"></i>
                                                        </span>
                                                    </RequireForAccess>
                                                </ReactAccessProvider>
                                            </li>
                                        </RequireForAccess>
                                    </ReactAccessProvider>
                                </Dropdown.Menu>
                            </ul>
                        </Dropdown>
                    </ListGroup.Item>
                </ListGroup>
            </React.Fragment>

            <AnimatedModal visible={showLanguageModal} onClose={() => setShowLanguageModal(false)} animation="rotate">
                <LanguageChangeModal editLang={selectedLanguage} handleCloseLanguageModal={() => setShowLanguageModal(false)} />
            </AnimatedModal>
        </>
    );
};

export default NavLeft;
